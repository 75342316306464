import React from "react";
import videoData from "../data/videoData";
import VideoSection from "../components/VideoSection";
import HeroAlternate from "../components/HeroAlternate";

export default function Videos() {
  return (
    <div>
      <HeroAlternate title="videos" />
      <div>
        <div id="videos">
          {videoData.map((video, i) => (
            <VideoSection
              key={video.id}
              id={video.id}
              index={i}
              title={video.title}
              titleAlt={video.titleAlt}
              description={video.description}
              youtubeURL={video.youtubeURL}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
